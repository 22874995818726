<template>
  <div>
    <b-card no-body header-tag="header" :class="d_fullscreen ? 'full-screen-mode' : ''" style="border: none;">
      <b-card-header :header-bg-variant="d_drawSettings.header_variant" class="p-1" :header-text-variant="d_drawSettings.header_variant === 'dark' ? 'white' : 'dark'">
        <b-row>
          <b-col cols="11">
            <template v-if="p_algorithmData.name">
              <template v-if="p_algorithmData.dmp_key">
                <a @click="f_goToWaimWdm24()" href="javascript:;">{{ p_algorithmData.name }}</a>
              </template>
              <template v-else>
                <i>{{ p_algorithmData.name }}</i>
              </template>
            </template>
            <template v-if="d_drawSettings.show_buttons">
              <b-button style="margin-right: 5px; padding: 0px;" variant="secondary" size="md" @click="f_minimizeCircles()">
                <img src="@/icon/248972.png" style="width: 2em;" />
              </b-button>
              <b-button style="margin-right: 5px; padding: 0px;" variant="secondary" size="md" @click="f_maximizeCircles()">
                <img src="@/icon/1910880.png" style="width: 2em;" />
              </b-button>
              <b-button style="margin-right: 5px; padding: 0px;" :title="$t('wdm16.11195')" variant="secondary" size="md" @click="f_rotateCirclesNegatif()">
                <img src="@/icon/615577.png" style="width: 2em; transform: scaleX(-1); -webkit-transform: scaleX(-1);" />
              </b-button>
              <b-button style="margin-right: 5px; padding: 0px;" :title="$t('wdm16.11196')" variant="secondary" size="md" @click="f_rotateCirclesPozitif()">
                <img src="@/icon/615577.png" style="width: 2em;" />
              </b-button>
              <b-button style="margin-right: 5px; padding: 0px;" :title="$t('wdm16.11197')" :variant="d_drawSettings.show_text ? 'warning' : 'secondary'" size="md" @click="f_showTextMode()">
                <img src="@/icon/487464.png" style="width: 2em;" />
              </b-button>
              <b-button style="margin-right: 5px; padding: 0px;" :title="$t('wdm16.11198')" variant="secondary" size="md" @click="f_updateColors()">
                <img src="@/icon/2173024.png" style="width: 2em;" />
              </b-button>
              <b-button style="margin-right: 5px; padding: 0px;" :title="$t('wdm16.11199')" :variant="d_drawSettings.colorAnimation ? 'warning' : 'secondary'" size="md" @click="f_startColorAnimation()">
                <img src="@/icon/403386.png" style="width: 2em;" />
              </b-button>
              <b-button v-if="d_drawSettings.colorAnimation" :title="$t('wdm16.11200')" style="margin-right: 5px; padding: 0px;" variant="secondary" size="md" @click="f_colorSpeed('+')">
                <img src="@/icon/3012411.png" style="width: 2em;" /> +
              </b-button>
              <b-button v-if="d_drawSettings.colorAnimation" :title="$t('wdm16.11201')" style="margin-right: 5px; padding: 0px;" variant="secondary" size="md" @click="f_colorSpeed('-')">
                <img src="@/icon/3012411.png" style="width: 2em;" /> -
              </b-button>
              <b-button style="margin-right: 5px; padding: 0px;" :title="$t('wdm16.11202')" :variant="d_drawSettings.rotateAnimation ? 'warning' : 'secondary'" size="md" @click="f_rotateAnimation()">
                <img src="@/icon/2957896.png" style="width: 2em;" />
              </b-button>
              <b-button v-if="d_drawSettings.rotateAnimation" :title="$t('wdm16.11203')" style="margin-right: 5px; padding: 0px;" variant="secondary" size="md" @click="f_rotateSpeed('+')">
                <img src="@/icon/3012411.png" style="width: 2em;" /> +
              </b-button>
              <b-button v-if="d_drawSettings.rotateAnimation" :title="$t('wdm16.11204')" style="margin-right: 5px; padding: 0px;" variant="secondary" size="md" @click="f_rotateSpeed('-')">
                <img src="@/icon/3012411.png" style="width: 2em;" /> -
              </b-button>
              <b-button style="margin-right: 5px; padding: 0px;" :title="$t('wdm16.11205')" variant="secondary" size="md" @click="f_changeDeep('+')">
                <img src="@/icon/1705440.png" style="width: 2em;" /> +
              </b-button>
              <b-button style="margin-right: 5px; padding: 0px;" :title="$t('wdm16.11206')" variant="secondary" size="md" @click="f_changeDeep('-')">
                <img src="@/icon/1705440.png" style="width: 2em;" /> -
              </b-button>
              <b-button v-if="d_drawSettings.max_deep > 1" style="margin-right: 5px; padding: 0px;" :title="$t('wdm16.11207')" :variant="d_drawSettings.deep_animation ? 'warning' : 'secondary'" size="md" @click="f_deepAnimation()">
                <img src="@/icon/1368397.png" style="width: 2em;" />
              </b-button>
              <b-button v-if="d_drawSettings.deep_animation" :title="$t('wdm16.11208')" style="margin-right: 5px; padding: 0px;" variant="secondary" size="md" @click="f_deepAnimationSpeed('+')">
                <img src="@/icon/2707569.png" style="width: 2em;" /> +
              </b-button>
              <b-button v-if="d_drawSettings.deep_animation" :title="$t('wdm16.11209')" style="margin-right: 5px; padding: 0px;" variant="secondary" size="md" @click="f_deepAnimationSpeed('-')">
                <img src="@/icon/2707569.png" style="width: 2em;" /> -
              </b-button>
              <b-button style="margin-right: 5px; padding: 0px;" :title="$t('wdm16.6589')" :variant="d_fullscreen ? 'warning' : 'secondary'" size="md" @click="f_fullScreenMode()">
                <img src="@/icon/629153.png" style="width: 2em;" />
              </b-button>
              <b-button v-if="p_algorithmData.dmp_rule_slider" style="margin-right: 5px; padding: 0px;" :title="$t('wdm16.11210')" :variant="p_algorithmData.stop ? 'warning' : 'secondary'" size="md" @click="p_algorithmData.stop ? p_algorithmData.stop = 0 : p_algorithmData.stop = 1">
                <img src="@/icon/672008.png" style="width: 2em;" />
              </b-button>
            </template>
          </b-col>
          <b-col cols="1" style="padding-right: 7px; text-align: right;">
            <b-button style="margin-right: 5px; padding: 0px;" variant="secondary" :title="$t('wdm16.4037')" size="md" @click="d_drawSettings.show_buttons ? d_drawSettings.show_buttons = false : d_drawSettings.show_buttons = true">
              <img src="@/icon/1430869.png" style="width: 2em;" />
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <canvas v-if="d_showCanvas" id="canvas" :width="d_drawSettings.canvas.width" :height="d_drawSettings.canvas.height" style="background: black;"></canvas>
    </b-card>
  </div>
</template>

<script>
import WaiService from '@/services/wai';
import { mapGetters } from 'vuex';

export default {
  name: 'DrawAlgorithm',
  components: {},
  props: {
    p_algorithmData: {
      type: Object,
      required: true
    },
    p_drawSettings: {
      type: Object,
      required: false
    },
    p_drawSpecificAlgorithmWdm24Id: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapGetters({
      device: 'device'
    })
  },
  data () {
    return {
      d_showButtons: true,
      d_showCanvas: true,
      d_fullscreen: false,
      tree_list_data: {},
      d_resetTreeListData: {},
      d_drawSettings: {
        'canvas': {
          'height': 500,
          'width': 750
        },
        'biggest_circle_radius': 30,
        'show_text': 1,
        'size': 100,
        'animation': 0,
        'header_variant': 'secondary',
        'lineWidth': 3,
        'colorAnimation': 0,
        'colorSpeed': 300,
        'rotateAnimation': 0,
        'shift': 1,
        'mainColor': this.f_calculateRGBA(),
        'speed': 500,
        'deep_animation_speed': 500,
        'max_deep': 0,
        'deep': 0,
        'deep_direction': 'to_children',
        'deep_animation': 0,
        'show_buttons': 1
      },
      d_colorAnimation: 0,
      d_rotateAnimation: 0,
      d_deepAnimation: 0,
      d_nextDrawAlgorithm: 0,
      d_drawAlgorithmData: {
        'dmp_key': 'new',
        'last_rule_ind': 'new',
        'last_dmp_ind': 'new',
        'list': []
      }
    }
  },
  created: function () {
    if (this.p_drawSettings) {
      for (let i in this.p_drawSettings) {
        this.d_drawSettings[i] = JSON.parse(JSON.stringify(this.p_drawSettings[i]));
      }
    }
    if (this.p_algorithmData.dmp_rule_slider && (this.p_algorithmData.dmp_rule_slider === 'all_dmp' || this.p_algorithmData.dmp_rule_slider === 'this_dmp')) {
      if (this.p_drawSpecificAlgorithmWdm24Id) {
        this.f_getSpecificDrawAlgorithm(this.p_drawSpecificAlgorithmWdm24Id);
      } else {
        this.f_getNextDrawAlgorithm();
        this.f_getNextDrawAlgorithmStartInterval();
      }
    } else {
      this.f_startOperation();
    }
    if (!this.d_drawSettings.mainColor) {
      this.d_drawSettings.mainColor = this.f_calculateRGBA();
    }
  },
  mounted: function () {
    this.f_startDrawing();
    if (this.d_drawSettings.rotateAnimation) {
      this.d_drawSettings.rotateAnimation = 0;
      this.f_rotateAnimation();
    }
    if (this.d_drawSettings.colorAnimation) {
      this.d_drawSettings.colorAnimation = 0;
      this.f_startColorAnimation();
    }
  },
  destroyed () {
    window.clearInterval(this.d_colorAnimation);
    window.clearInterval(this.d_rotateAnimation);
    window.clearInterval(this.d_deepAnimation);
    window.clearInterval(this.d_nextDrawAlgorithm);
  },
  methods: {
    f_goToWaimWdm24: function () {
      let route = this.$router.resolve({ path: '/whsmodules/polestar/waim/talk-algorithm', query: { 'waim_id': this.p_algorithmData.dmp_key.split('_')[1] } });
      window.open(route.href, '_blank');
    },
    f_getNextDrawAlgorithmStartInterval: function () {
      this.d_nextDrawAlgorithm = setInterval(function () {
        this.f_getNextDrawAlgorithm();
      }.bind(this), 10000);
    },
    f_getNextDrawAlgorithm: function () {
      let dmp_rule_slider = '';
      let dmp_key = '';
      if (this.p_algorithmData.dmp_rule_slider) {
        dmp_rule_slider = this.p_algorithmData.dmp_rule_slider;
      }
      if (this.p_algorithmData.dmp_key && this.p_algorithmData.dmp_rule_slider === 'this_dmp') {
        dmp_key = this.p_algorithmData.dmp_key;
      }
      let query = 'dmp_key=' + dmp_key + '&last_rule_ind=' + this.d_drawAlgorithmData.last_rule_ind.toString() + '&last_dmp_ind=' + this.d_drawAlgorithmData.last_dmp_ind.toString() + '&dmp_rule_slider=' + dmp_rule_slider;
      WaiService.get_next_draw_algorithm(query)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_drawAlgorithmData.dmp_key = resp.data.result.dmp_key;
            this.p_algorithmData.dmp_key = resp.data.result.dmp_key;
            this.d_drawAlgorithmData.last_rule_ind = resp.data.result.last_rule_ind;
            this.d_drawAlgorithmData.last_dmp_ind = resp.data.result.last_dmp_ind;
            if (resp.data.result.list.length > 0) {
              this.p_algorithmData.data = resp.data.result.list;
              this.p_algorithmData.name = resp.data.result.dmp_name;
              this.f_startOperation();
              this.f_startDrawing();
              this.$forceUpdate();
              // this.f_algorithmOperation('update_canvas');
            }
          } else {
            console.log('Error :' + resp.data.message);
          }
        });
    },
    f_getSpecificDrawAlgorithm: function (wdm24_id) {
      let dmp_rule_slider = '';
      let query = 'wdm24_id=' + wdm24_id;
      WaiService.get_specific_draw_algorithm(query)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_drawAlgorithmData.dmp_key = resp.data.result.dmp_key;
            this.p_algorithmData.dmp_key = resp.data.result.dmp_key;
            if (resp.data.result.list.length > 0) {
              this.p_algorithmData.data = resp.data.result.list;
              this.p_algorithmData.name = resp.data.result.dmp_name;
              this.f_startOperation();
              this.f_startDrawing();
              this.$forceUpdate();
              // this.f_algorithmOperation('update_canvas');
            }
          } else {
            console.log('Error :' + resp.data.message);
          }
        });
    },
    f_startOperation: function () {
      if (this.p_algorithmData) {
        this.f_createTreeListDataFromRuleAlgorithm();
        this.f_createRandomColors(this.tree_list_data.list, true);
      }
      let turning_data = { 'deep': 0, 'max_deep': 0 };
      this.f_getMaximumDeep(this.tree_list_data.list, turning_data);
      this.d_drawSettings.max_deep = turning_data.max_deep;
      this.d_drawSettings.deep = turning_data.max_deep;
      this.d_resetTreeListData = JSON.parse(JSON.stringify(this.tree_list_data));
    },
    f_deepAnimationSpeed: function (type) {
      if (type === '-') {
        this.d_drawSettings.deep_animation_speed += 50;
      } else {
        // +
        this.d_drawSettings.deep_animation_speed -= 50;
      }
      window.clearInterval(this.d_deepAnimation);
      this.f_deepAnimationOperation();
    },
    f_deepAnimation: function () {
      if (this.d_drawSettings.deep_animation) {
        this.d_drawSettings.deep_animation = 0;
        window.clearInterval(this.d_deepAnimation);
      } else {
        this.d_drawSettings.deep_animation = 1;
        this.f_deepAnimationOperation();
      }
    },
    f_deepAnimationOperation: function () {
      this.d_deepAnimation = setInterval(function () {
        if (this.d_drawSettings.deep_direction === 'to_children') {
          if (this.d_drawSettings.deep < this.d_drawSettings.max_deep && this.d_drawSettings.deep !== this.d_drawSettings.max_deep) {
            this.d_drawSettings.deep += 1;
          } else {
            this.d_drawSettings.deep_direction = 'to_parent';
            this.d_drawSettings.deep -= 1;
          }
        } else if (this.d_drawSettings.deep_direction === 'to_parent') {
          if (this.d_drawSettings.deep !== 0) {
            this.d_drawSettings.deep -= 1;
          } else {
            this.d_drawSettings.deep_direction = 'to_children';
            this.d_drawSettings.deep += 1;
          }
        }
        this.f_startDrawing();
      }.bind(this), this.d_drawSettings.deep_animation_speed);
    },
    f_changeDeep: function (type) {
      if (type === '-' && this.d_drawSettings.deep !== 0) {
        this.d_drawSettings.deep -= 1;
      } else if (type === '+' && this.d_drawSettings.deep < this.d_drawSettings.max_deep) {
        this.d_drawSettings.deep += 1;
      }
      this.f_startDrawing();
    },
    f_rotateSpeed: function (type) {
      if (type === '-') {
        this.d_drawSettings.speed += 50;
      } else {
        // +
        this.d_drawSettings.speed -= 50;
      }
      window.clearInterval(this.d_rotateAnimation);
      this.d_rotateAnimation = setInterval(function () {
        this.f_rotateCirclesPozitif();
      }.bind(this), this.d_drawSettings.speed);
    },
    f_colorSpeed: function (type) {
      if (type === '-') {
        this.d_drawSettings.colorSpeed += 50;
      } else {
        // +
        this.d_drawSettings.colorSpeed -= 50;
      }
      window.clearInterval(this.d_colorAnimation);
      this.d_colorAnimation = setInterval(function () {
        this.f_updateColors();
      }.bind(this), this.d_drawSettings.colorSpeed);
    },
    f_fullScreenMode: function () {
      this.d_showCanvas = false;
      setTimeout(function () {
        if (this.d_fullscreen) {
          if (this.p_drawSettings) {
            this.d_drawSettings.canvas.height = this.p_drawSettings.canvas.height;
            this.d_drawSettings.canvas.width = this.p_drawSettings.canvas.width;
          } else {
            this.d_drawSettings.canvas.height = 500;
            this.d_drawSettings.canvas.width = 750;
          }
          this.d_fullscreen = false;
        } else {
          this.d_drawSettings.canvas.height = window.innerHeight - 50;
          this.d_drawSettings.canvas.width = window.innerWidth;
          this.d_fullscreen = true
        }
        this.d_showCanvas = true;
        setTimeout(function () {
          this.f_startDrawing();
        }.bind(this), 500);
      }.bind(this), 500);
    },
    f_rotateAnimation: function () {
      if (this.d_drawSettings.rotateAnimation) {
        this.d_drawSettings.rotateAnimation = 0;
        window.clearInterval(this.d_rotateAnimation);
      } else {
        this.d_drawSettings.rotateAnimation = 1;
        this.d_rotateAnimation = setInterval(function () {
          this.f_rotateCirclesPozitif();
        }.bind(this), this.d_drawSettings.speed);
      }
    },
    f_rotateCirclesPozitif: function () {
      this.d_drawSettings.shift += 0.03;
      this.f_startDrawing();
    },
    f_rotateCirclesNegatif: function () {
      this.d_drawSettings.shift -= 0.03;
      this.f_startDrawing();
    },
    f_startColorAnimation: function () {
      if (this.d_drawSettings.colorAnimation) {
        this.d_drawSettings.colorAnimation = 0;
        window.clearInterval(this.d_colorAnimation);
      } else {
        this.d_drawSettings.colorAnimation = 1;
        this.d_colorAnimation = setInterval(function () {
          this.f_updateColors();
        }.bind(this), this.d_drawSettings.colorSpeed);
      }
    },
    f_updateColors: function () {
      this.d_drawSettings.mainColor = this.f_calculateRGBA();
      this.f_createRandomColors(this.tree_list_data.list, true);
      this.f_startDrawing();
    },
    f_showTextMode: function () {
      if (this.d_drawSettings.show_text) {
        this.d_drawSettings.show_text = 0;
      } else {
        this.d_drawSettings.show_text = 1;
      }
      this.f_startDrawing();
    },
    f_minimizeCircles: function () {
      if (this.d_drawSettings.biggest_circle_radius > 20) {}
      this.d_drawSettings.biggest_circle_radius -= (this.d_drawSettings.biggest_circle_radius * 5) / 100;
      this.d_drawSettings.size -= (this.d_drawSettings.size * 5) / 100;
      this.f_startDrawing();
    },
    f_maximizeCircles: function () {
      if (this.d_drawSettings.biggest_circle_radius < 100) {}
      this.d_drawSettings.biggest_circle_radius += (this.d_drawSettings.biggest_circle_radius * 5) / 100;
      this.d_drawSettings.size += (this.d_drawSettings.size * 5) / 100;
      this.f_startDrawing();
    },
    f_startDrawing: function () {
      let canvas = document.getElementById("canvas");
      if (!canvas) {
        return;
      }
      if (!this.tree_list_data.list) {
        return;
      }
      let ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      // this.f_mouseCanvas(ctx);
      // console.log('canvas.height', canvas.height);
      // console.log('canvas.width', canvas.width);
      let canvas_center_x = Math.round(this.d_drawSettings.canvas.width / 2);
      let canvas_center_y = Math.round(this.d_drawSettings.canvas.height / 2);
      // this.f_drawGeometry(canvas_center_x, canvas_center_y, 5, ctx);
      let turning_data = {
        'radius': this.d_drawSettings.biggest_circle_radius,
        'lineWidth': this.d_drawSettings.lineWidth,
        'size': this.d_drawSettings.size,
        'upperBackgroundColor': '',
        'mainColor': this.d_drawSettings.mainColor,
        'deep': 0
      };
      this.f_drawFromTreeListData(this.tree_list_data.list, ctx, canvas_center_x, canvas_center_y, turning_data);
      this.f_drawNodeCircle(ctx, canvas_center_x, canvas_center_y, 5, 1, this.d_drawSettings.mainColor);
      if (this.d_drawSettings.show_text) {
        this.f_addTextOfTreeListData(this.tree_list_data.list, ctx, turning_data);
      }
      /*
        ***this.tree_list_data***
        // we will have a tree list data like object.
        => {'list': []} => lists in every list array key
        example tree_list_data = {
          "list":
            [{
                "value": "wdm7",
                "label": "Hasta Bilgileri (wisdom) ( 2.0)",
                "x": 0,
                "y": 0,
                "list": [{
                  "value": "general_sex",
                  "label": "Genel Cinsiyet",
                  "x": 0,
                  "y": 0,
                  "list": []
                }]
              }, {
                "value": "pathology",
                "label": "patoloji (wisdom) ( 4.0)",
                "x": 0,
                "y": 0,
                "list": {
                  "value": "general_neoadjuvant_treatment_status",
                  "label": "Genel parametreler neoadjuvan tedavi uygulandı mı",
                  "x": 0,
                  "y": 0,
                  "list": []
                }]
            }, {
              "value": "patient_staging",
              "label": "evreleme (wisdom) ( 4.0)",
              "x": 0,
              "y": 0,
              "list": [{
                "value": "general_staging_method",
                "label": "Genel bilgiler evreleme metodu",
                "x": 0,
                "y": 0,
                "list": []
              }]
            }]
        }

      */
    },
    f_mouseCanvas: function (ctx) {
      var canvasOffset = $("#canvas").offset();
      var offsetX = canvasOffset.left;
      var offsetY = canvasOffset.top;

      function draw(x, y) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.beginPath();
        ctx.fillStyle = "skyblue";
        ctx.strokeStyle = "gray";
        ctx.rect(x, y, 30, 20);
        ctx.fill();
        ctx.stroke();
      }

      function handleMouseDown(e) {
        mouseX = parseInt(e.clientX - offsetX);
        mouseY = parseInt(e.clientY - offsetY);
        $("#downlog").html("Down: " + mouseX + " / " + mouseY);

        // Put your mousedown stuff here
        draw(mouseX, mouseY);

      }

      $("#canvas").mousedown(function (e) { handleMouseDown(e); });

      // start the rect at [10,10]
      draw(10, 10);
    },
    f_createTreeListDataFromRuleAlgorithm: function () {
      this.tree_list_data = { 'list': [] };
      for (let i in this.p_algorithmData.data) {
        for (let q in this.p_algorithmData.data[i].query_list) {
          let q_type = this.p_algorithmData.data[i].query_list[q].type.value;
          let q_label = this.p_algorithmData.data[i].query_list[q].type.label;
          let q_det = false;
          let li_q_index = '';
          for (let li in this.tree_list_data.list) {
            if (this.tree_list_data.list[li].value === q_type) {
              q_det = true;
              li_q_index = parseInt(li);
              break;
            }
          }
          if (!q_det) {
            let new_li = {
              'value': q_type,
              'label': q_label,
              'x': 0,
              'y': 0,
              'list': []
            };
            this.tree_list_data.list.push(new_li);
            li_q_index = this.tree_list_data.list.length - 1;
          }
          for (let f in this.p_algorithmData.data[i].query_list[q].fields) {
            let field_data = this.p_algorithmData.data[i].query_list[q].fields[f];
            let loc = field_data['loc'];
            let arr = this.f_getLocStr(loc);
            let loc_str = arr[0];
            let f_label = arr[1];
            let f_det = false;
            let q_f_index = '';
            for (let li_2 in this.tree_list_data.list[li_q_index].list) {
              if (this.tree_list_data.list[li_q_index].list[li_2].value === loc_str) {
                q_f_index = parseInt(li_2);
                f_det = true;
              }
            }
            if (!f_det) {
              let new_li = {
                'value': loc_str,
                'label': f_label,
                'x': 0,
                'y': 0,
                'list': []
              };
              this.tree_list_data.list[li_q_index].list.push(new_li);
              q_f_index = this.tree_list_data.list[li_q_index].list.length - 1;
            }
            for (let v in field_data.val_list) {
              let v_det = false;
              let v_label = '';
              let v_value = '';
              let val = field_data.val_list[v];
              if (typeof val === 'object' && 'label' in val) {
                v_label = val['label'];
                v_value = val['value'];
              } else {
                v_label = val.toString();
                v_value = val.toString();
              }
              for (let li_3 in this.tree_list_data.list[li_q_index].list[q_f_index].list) {
                if (this.tree_list_data.list[li_q_index].list[q_f_index].value === v_value) {
                  v_det = true;
                }
              }
              if (!v_det) {
                let new_li = {
                  'value': v_value,
                  'label': v_label,
                  'x': 0,
                  'y': 0,
                  'list': []
                };
                this.tree_list_data.list[li_q_index].list[q_f_index].list.push(new_li);
              }
            }
          }
        }
      }
      return this.tree_list_data;
    },
    f_getLocStr: function (loc) {
      let loc_str = '';
      let f_label = '';
      for (let i in loc) {
        loc_str += loc[i].value;
        if (parseInt(i) !== loc.length - 1) {
          loc_str += '_';
        }
      }
      f_label = loc[loc.length - 1].label;
      return [loc_str, f_label];
    },
    f_drawGeometry: function (x, y, numberOfSides, ctx) {
      var size = 100,
        step = 2 * Math.PI / numberOfSides, //Precalculate step value
        shift = (Math.PI / 180.0) * -18; //Quick fix ;)

      ctx.beginPath();
      //ctx.moveTo (Xcenter +  size * Math.cos(0), Ycenter +  size *  Math.sin(0));
      for (var i = 0; i <= numberOfSides; i++) {
        var curStep = i * step + shift;
        ctx.lineTo(x + size * Math.cos(curStep), y + size * Math.sin(curStep));
      }
      ctx.strokeStyle = "#000000";
      ctx.lineWidth = 1;
      ctx.stroke();
    },
    f_drawFromTreeListData: function (list_data, ctx, center_x, center_y, turning_data) {
      let size = turning_data.size;
      let step = 2 * Math.PI / list_data.length; // Precalculate step value
      // let shift = (Math.PI / 180.0) * -18; // Quick fix
      let shift = this.d_drawSettings.shift; // Quick fix
      // ctx.beginPath();
      for (let i in list_data) {
        let curStep = (parseInt(i)) * step + shift;
        let cosCurrentStep = Math.cos(curStep);
        let sinCurrentStep = Math.sin(curStep);
        let new_x = center_x + size * cosCurrentStep;
        let new_y = center_y + size * sinCurrentStep;
        list_data[i].x = new_x;
        list_data[i].y = new_y;
        if (turning_data.deep === 0) {
          turning_data.upperBackgroundColor = turning_data.mainColor;
        }
        this.f_drawLine(ctx, center_x, center_y, new_x, new_y, turning_data.upperBackgroundColor, turning_data.lineWidth, this.d_drawSettings.animation);
        this.f_drawNodeCircle(ctx, new_x, new_y, turning_data.radius, turning_data.lineWidth, list_data[i].backgroundColor);
        /*
        if we want to link cengter points of circles we can use below code
        if (parseInt(i) > 0) {
          // Here means we have an item before this item, then we can use its x, y coordinates for move to function
          ctx.moveTo(list_data[parseInt(i) - 1].x, list_data[parseInt(i) - 1].y);
          ctx.lineTo(new_x, new_y);
          ctx.stroke();
        }
        if (parseInt(i) === list_data.length - 1 && list_data.length > 2) {
          // we need to finish all path of the points to be combined to each other. Then we draw also a line grom the zero item point and end item point.
          ctx.moveTo(list_data[0].x, list_data[0].y);
          ctx.lineTo(list_data[list_data.length - 1].x, list_data[list_data.length - 1].y);
          ctx.stroke();
        }
        */
        // ctx.lineTo(new_x, new_y);
        if (list_data[i].list && list_data[i].list.length > 0 && (turning_data.deep + 1) <= this.d_drawSettings.deep) {
          turning_data.deep += 1;
          let sizePercent = (turning_data.size * 30) / 100;
          turning_data.size -= sizePercent;
          let lineWidthPercent = (turning_data.lineWidth * 50) / 100;
          turning_data.lineWidth -= lineWidthPercent;
          let rad_percent = (turning_data.radius * 50) / 100;
          turning_data.radius -= rad_percent;
          let old_upper_background_color = turning_data.upperBackgroundColor;
          turning_data.upperBackgroundColor = list_data[i].backgroundColor;
          this.d_drawSettings.shift += 0.20;
          this.f_drawFromTreeListData(list_data[i].list, ctx, new_x, new_y, turning_data);
          turning_data.upperBackgroundColor = old_upper_background_color;
          this.d_drawSettings.shift -= 0.20;
          turning_data.deep -= 1;
          turning_data.radius += rad_percent;
          turning_data.lineWidth += lineWidthPercent;
          turning_data.size += sizePercent;
        }
      }
      /*
      ctx.strokeStyle = "#000000";
      ctx.lineWidth = 1;
      ctx.stroke();
      */
    },
    f_addTextOfTreeListData: function (list_data, ctx, turning_data) {
      for (let i in list_data) {
        ctx.font = '12px Arial';
        ctx.fillStyle = 'white';
        ctx.fillText(list_data[i].label, list_data[i].x, list_data[i].y);
        if (list_data[i].list && list_data[i].list.length > 0 && (turning_data.deep + 1) <= this.d_drawSettings.deep) {
          turning_data.deep += 1;
          this.f_addTextOfTreeListData(list_data[i].list, ctx, turning_data);
          turning_data.deep -= 1;
        }
      }
    },
    f_getMaximumDeep: function (list_data, turning_data) {
      for (let i in list_data) {
        if (list_data[i].list && list_data[i].list.length > 0) {
          turning_data.deep += 1;
          if (turning_data.max_deep < turning_data.deep) {
            turning_data.max_deep = turning_data.deep;
          }
          this.f_getMaximumDeep(list_data[i].list, turning_data);
          turning_data.deep -= 1;
        }
      }
    },
    f_createRandomColors: function (list_data, update) {
      for (let i in list_data) {
        if (update) {
          list_data[i].backgroundColor = this.f_calculateRGBA();
        }
        if (list_data[i].list && list_data[i].list.length > 0) {
          this.f_createRandomColors(list_data[i].list, update);
        }
      }
    },
    f_drawNodeCircle: function (ctx, x, y, r, lineWidth, backgroundColor) {
      ctx.beginPath();
      ctx.arc(x, y, r, 0, 2 * Math.PI);
      ctx.lineWidth = lineWidth;
      ctx.strokeStyle = backgroundColor;
      ctx.stroke();
      ctx.fillStyle = backgroundColor;
      ctx.fill();
    },
    f_drawLine: function (ctx, x1, y1, x2, y2, backgroundColor = '', lineWidth, animation = 0) {
      if (animation) {
        setTimeout(function () {
          ctx.beginPath();
          ctx.moveTo(x1, y1);
          ctx.lineTo(x2, y2);
          ctx.lineWidth = lineWidth;
          ctx.strokeStyle = backgroundColor;
          ctx.stroke();
        }.bind(this), 500);
      } else {
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = backgroundColor;
        ctx.stroke();
      }
    },
    f_calculateRGBA: function () {
      let r = Math.floor(Math.random() * 256);
      let g = Math.floor(Math.random() * 256);
      let b = Math.floor(Math.random() * 256);
      return 'rgba(' + r.toString() + ',' + g.toString() + ',' + b.toString() + ', 1)';
    }
  },
  watch: {
    'p_algorithmData.update_canvas': function () {
      this.f_startOperation();
      this.f_startDrawing();
      this.$forceUpdate();
    },
    'p_algorithmData.stop': function () {
      if (this.p_algorithmData.stop) {
        clearInterval(this.d_nextDrawAlgorithm);
      } else {
        this.f_getNextDrawAlgorithmStartInterval();
      }
    }
  }
};

</script>

<style type="text/css">
.full-screen-mode {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

</style>

