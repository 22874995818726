import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  get_next_wai (query) {
    return Vue.http.get(API_BASE_URL + 'wai/get_next_wai?' + query);
  },
  get_next_draw_algorithm (query) {
    return Vue.http.get(API_BASE_URL + 'wai/get_next_draw_algorithm?' + query);
  },
  get_specific_draw_algorithm (query) {
    return Vue.http.get(API_BASE_URL + 'wai/get_specific_draw_algorithm?' + query);
  },
  get_user_profile (query) {
    return Vue.http.get(API_BASE_URL + 'wai/get_user_profile?' + query);
  },
  save_wdm22 (data) {
    return Vue.http.post(API_BASE_URL + 'wai/save_wdm22', data);
  },
  search_wai (data) {
    return Vue.http.post(API_BASE_URL + 'wai/search_wai', data);
  },
  analyze_dmp (data) {
    return Vue.http.post(API_BASE_URL + 'wai/service/analyze_dmp', data);
  },
  analyze_dmp_test (data) {
    return Vue.http.post(API_BASE_URL + 'wai/analyze_dmp_test', data);
  }
};
