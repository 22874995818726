<template>
  <div style="background: black;">
    <wai-dmp-talk></wai-dmp-talk>
  </div>
</template>

<script>
import OptionService from '@/services/option';
import GlobalService from '@/services/global';
import {
  default as WaiDmpTalk
} from "@/components/widgets/WaiDmpTalk";
import { mapGetters } from 'vuex';
export default {
  name: 'TalkAlgorithm',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    WaiDmpTalk
  },
  props: {},
  data () {
    return {};
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {},
  watch: {}
};

</script>

