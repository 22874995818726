<template>
  <div>
    <b-card class="animated fadeIn" style="min-height: 750px;">
      <b-card-header header-bg-variant="white" class="p-1">
        <b-row style="margin: 0px;">
          <b-col sm="12" md="9">
            {{ $t('wdm16.11220') }} {{ d_selectedDmpWdmrData.label }}
          </b-col>
          <b-col sm="12" md="3" style="text-align: right;">
            <b-button v-if="d_selectedTab === 'analyze_page'" variant="white" @click="f_analyzeDmpTest()">
              <img src="@/icon/2324511.png" class="img-rounded img-responsive" style="width: 1.5em;" /> {{ $t('wdm16.11145') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-tabs>
        <b-tab active @click="d_selectedTab = 'about_dmp'">
          <template slot="title">
            <img src="@/icon/665778.png" style="width: 1.5em;"> {{ $t('wdm16.11221') }}
          </template>
          <template v-if="d_selectedDmpWdmrData.id">
            <b-row v-if="d_selectedDmpWdmrData.data.general.information" style="margin: 0px; border: solid 1px #d8f6d8; padding: 3px; border-radius: 5px; min-height: 50px;">
              <b-col sm="12" md="3">
                {{ $t('wdm16.3317') }}
              </b-col>
              <b-col sm="12" md="9">
                {{ d_selectedDmpWdmrData.data.general.information.val }}
              </b-col>
            </b-row>
            <b-row v-if="d_selectedDmpWdmrData.data.general.about_algotihms && d_selectedDmpWdmrData.data.general.about_algotihms.list.length > 0" style="margin: 0px; border: solid 1px #d8f6d8; padding: 3px; border-radius: 5px;">
              <template v-for="(about, about_ind) in d_selectedDmpWdmrData.data.general.about_algotihms.list">
                <b-col sm="12" md="3">
                  <template v-if="about.image && about.image.val">
                    <img :src="about.image.val" style="width: 100%;">
                    <br>
                  </template>
                  <template v-if="about.about_information && about.about_information.val">
                    <span style="margin-bottom: 0px;">{{ about.about_information.val }}</span>
                  </template>
                </b-col>
              </template>
            </b-row>
            <b-row :style="'height:' + (d_drawSettings.canvas.height + 50) + 'px; margin: 0px;'" class="animated fadeIn">
              <b-col sm="12">
                <template v-if="d_algorithmData.show && d_algorithmData.dmp_key">
                  <draw-algorithm :p_algorithmData="d_algorithmData" :p_drawSettings="d_drawSettings" :p_drawSpecificAlgorithmWdm24Id="d_wdmr24_id"></draw-algorithm>
                </template>
              </b-col>
            </b-row>
          </template>
        </b-tab>
        <b-tab @click="d_selectedTab = 'analyze_page'">
          <template slot="title">
            <img src="@/icon/9370.png" style="width: 1.5em;"> {{ $t('wdm16.11145') }}
          </template>
          <template v-if="d_selectedTab === 'analyze_page' && d_showMultiWdmr && d_selectedDmpWdmrData && d_selectedDmpWdmrData.id && d_selectedDmpWdmrData.data.general.wdm && d_selectedDmpWdmrData.data.general.wdm.list.length > 0">
            <multi-wdmr :p_dmpWdmr="d_selectedDmpWdmrData" :p_multiWdmrData="d_multiWdmrData" :p_waiTalkAlgorithm="true"></multi-wdmr>
          </template>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-modal v-if="d_ruleAnalyzeData.show" v-model="d_ruleAnalyzeData.show" size="lg" :title="$t('wdm16.11193')" centered hide-footer body-bg-variant="secondary" header-bg-variant="secondary">
      <rule-engine :rule_results="d_ruleAnalyzeData.results" :dmp_analyze_type="'product'"></rule-engine>
    </b-modal>
  </div>
</template>

<script>
import WaiService from "@/services/wai";
import { ClsWdmrForever, ClsWdmrSave } from "@/services/public/wdm";
/*
  import { ClsRule } from '@/services/public/rule';
  import {
    default as WisdomDataShow
  } from '@/components/widgets/WisdomDataShow';
  import WdmService from '@/services/wdm';
  import OptionService from "@/services/option";
*/
import {
  default as DrawAlgorithm
} from '@/components/widgets/DrawAlgorithm';
import {
  default as RuleEngine
} from '@/components/widgets/RuleEngine';
import {
  default as Modal
} from "@/components/widgets/Modal";
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import {
  default as MultiWdmr
} from '@/components/widgets/MultiWdmr';
import { mapGetters } from 'vuex';
import RuleEngineService from "@/services/rule_engine";
import GlobalService from "@/services/global";
export default {
  name: 'WaiDmpTalk',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    RuleEngine,
    MultiWdmr,
    // WisdomDataShow,
    WisdomDataModal,
    Modal,
    DrawAlgorithm
  },
  props: {},
  data () {
    return {
      d_selectedTab: 'about_dmp',
      d_showMultiWdmr: true,
      d_ruleAnalyzeData: { 'results': {}, 'show': false },
      d_selectedDmpWdmrData: '',
      d_wdmr24_key: '',
      d_multiWdmrData: {
        'wdmr_settings': {
          'wisdom_param': {
            'settings': 0,
            'mode': 1, // 1 normal, 2 label value mode up down parameter, 3 label value left to right,
            'style': {
              'caption': {
                'fontSize': 12,
                'color': 'black',
                'after': '',
                'backgroundColor': 'white',
                'border': {
                  'type': 'solid',
                  'size': 0,
                  'color': 'black'
                },
                'borderRadius': 0
              },
              'value': {
                'fontSize': 10,
                'color': 'black',
                'after': '',
                'backgroundColor': 'white',
                'border': {
                  'type': 'solid',
                  'size': 0,
                  'color': 'black'
                },
                'borderRadius': 0
              }
            }
          },
          'wdm_designer': {
            'show': 0,
            'settings': 0,
            'table': []
          },
          'right_page_mode': {
            'page': ''
          },
          'column_settings': {
            'cols': 1
          },
          'wdmr_schema': {
            'wdm_anatomy': {
              'show': 0,
              'view_mode': 'basic'
            },
            'wdm': {
              'show': 0,
              'view_mode': 'basic'
            }
          },
          'multi_wdmr_schema': {
            'schema_view_mode': 'basic'
          },
          'header_button': 1,
          'mode': 'basic',
          'bmi_gfr_widget': {
            'show_mode': 'left_right'
          },
          'page_divide': 3,
          'settings': {
            'show': 0
          },
          'anatomy_component': {
            'mode': 2
          },
          'anatomy_group': {
            'cols': 1
          },
          'favorite_show_mode': 0,
          'group': {
            'show': 1,
            'mode': 1
          },
          'date': {
            'show_as_row': 0
          },
          'wdmr_header_mode': 2, // 1 or 2 or 3 or 4
          'document_upload': {
            'show': 0
          }
        },
        'multi_wdmr_list': [],
        'selected_multi_wdmr': '',
        'selected_schema_change': 0
      },
      user: {},
      d_algorithmData: {
        'show': true,
        'data': [],
        'dmp_rule_slider': 'this_dmp',
        'dmp_key': '',
        'stop': 0,
        'next': 0,
        'update_canvas': 0,
        'name': ''
      },
      d_drawSettings: {
        'canvas': {
          'height': 250,
          'width': window.innerWidth
        },
        'header_variant': 'dark',
        'biggest_circle_radius': 30,
        'show_text': 1,
        'size': 200,
        'animation': 0,
        'lineWidth': 3,
        'colorAnimation': 1,
        'colorSpeed': 500,
        'rotateAnimation': 1,
        'shift': 1,
        'mainColor': '',
        'speed': 100,
        'deep_animation_speed': 500,
        'max_deep': 0,
        'deep': 0,
        'deep_direction': 'to_children',
        'deep_animation': 0,
        'show_buttons': 0
      },
      d_wdmr24_id: ''
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.$route.query.waim_id) {
      this.d_wdmr24_key = 'wdm24_' + this.$route.query.waim_id;
      this.d_wdmr24_id = this.$route.query.waim_id;
    }
    if (this.d_wdmr24_key !== '') {
      this.f_getDmpWdmr24Data(this.d_wdmr24_key, 'wisdom');
      this.d_algorithmData.dmp_key = this.d_wdmr24_key;
    }
  },
  mounted: function () {},
  methods: {
    f_getDmpWdmr24Data: function (key, bucket) {
      let data = { 'key': key, 'bucket': bucket };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
      GlobalService.get_one_cb_document(data)
        .then(resp => {
          let msg = '';
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === "success") {
            this.d_selectedDmpWdmrData = resp.data.result;
            this.f_showMultiWdmr();
          } else {
            alert('errorr f_getWdmrData : ', resp.data.message);
          }
        });
    },
    f_showMultiWdmr: function () {
      this.d_showMultiWdmr = false;
      setTimeout(function () {
        this.d_showMultiWdmr = true;
      }.bind(this), 500);
    },
    f_analyzeDmpTest: function () {
      let data = {
        'wdmr': {},
        'dmp': {}
      };
      let dmp_id = this.d_selectedDmpWdmrData.id;
      data.dmp[dmp_id] = {
        'type': 'all',
        'list': []
      };
      for (let i in this.d_multiWdmrData.multi_wdmr_list) {
        let data_type = this.d_multiWdmrData.multi_wdmr_list[i].data_type;
        if (!data.wdmr[data_type]) {
          data.wdmr[data_type] = [];
        }
        let wisdom_data = JSON.parse(JSON.stringify(this.d_multiWdmrData.multi_wdmr_list[i].wisdom_data));
        let option_data = this.d_multiWdmrData.multi_wdmr_list[i].option_data[data_type];
        // let return_data = ClsWdmrForever.turn(wisdom_data, option_data, ['delete', 'lang_id_deletion', 'val_label_deletion']);
        // when we clean something below code, we post a small request data.
        let return_data = ClsWdmrForever.turn(wisdom_data, option_data, ['delete', 'lang_id_deletion']);
        if (wisdom_data['date_type'] !== undefined) {
          this.$delete(wisdom_data, 'date_type');
        }
        if (wisdom_data['ending_date_type'] !== undefined) {
          this.$delete(wisdom_data, 'ending_date_type');
        }
        if (wisdom_data['reference_wdm'] !== undefined) {
          this.$delete(wisdom_data, 'reference_wdm');
        }
        data.wdmr[data_type].push(wisdom_data);
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Analizleriniz yapılıyor bekleyiniz.' } });
      WaiService.analyze_dmp_test(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_ruleAnalyzeData.results = { 'list': resp.data.result.dmp };
            if (Object.keys(this.d_ruleAnalyzeData.results.list).length > 0) {
              this.d_ruleAnalyzeData.show = true;
            }
          } else {
            // alert('Error ', resp.data.message);
          }
        });
    },
    f_analyzeWdmrWithRules: function () {
      /*
      data = {
          'wdmr_list': {'genetic': [{'data': {'general': {...}}}, ...], 'pathology': [{'data': {'anatomy': {...}}}, ...], .. },
          'dmp_list': {
              'wdm24_1': {
                  'label': 'Karar destek paketi adi',
                  'bucket': 'wisdom',
                  'rule_list': {
                      'rule_4': {
                          'wdm_list': {
                              'genetic': {
                                  loc: []
                              }
                          }
                      }
                  },
                  'wdm_key_list': ['wdm_wisdom_genetic_v2.0', ...]
              }
          }
      }
      */
      let data = {
        'wdmr_list': {},
        'dmp_list': {}
      };
      let dmp_key = 'wdm24_' + this.d_selectedDmpWdmrData.id;
      data.dmp_list[dmp_key] = {
        'label': this.d_selectedDmpWdmrData.label,
        'bucket': 'wisdom',
        'rule_list': {},
        'rule_type': 'all'
      };
      for (let i in this.d_multiWdmrData.multi_wdmr_list) {
        let data_type = this.d_multiWdmrData.multi_wdmr_list[i].data_type;
        if (!data.wdmr_list[data_type]) {
          data.wdmr_list[data_type] = [];
        }
        data.wdmr_list[data_type].push(this.d_multiWdmrData.multi_wdmr_list[i].wisdom_data);
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Analizleriniz yapılıyor bekleyiniz.' } });
      RuleEngineService.analyze_wdmr_list(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            // console.log(resp.data.result);
            this.d_ruleAnalyzeData.results = resp.data.result;
            if (Object.keys(this.d_ruleAnalyzeData.results.list).length > 0) {
              this.d_ruleAnalyzeData.show = true;
            }
          } else {
            alert('Error ', resp.data.message);
          }
        })
    }
  },
  watch: {}
}

</script>

<style>
.s_showQuickIcon {
  height: 75px;
  width: 35px;
  border-radius: 50px 0 0 50px;
  background-color: rgba(255, 0, 0, 0.9);
  box-shadow: -4px -1px 6px 2px #716b6b;
  border: solid 1px 0px 1px 1px #716b6b;
  position: fixed;
  z-index: 1000;
  top: 100px;
  right: 250px;
  cursor: pointer;
}

.s_showQuickIconNot {
  height: 75px;
  width: 35px;
  border-radius: 50px 0 0 50px;
  background-color: rgba(255, 0, 0, 0.9);
  box-shadow: -4px -1px 6px 2px #716b6b;
  border: solid 1px 0px 1px 1px #716b6b;
  position: fixed;
  z-index: 1000;
  top: 100px;
  right: 0px;
  cursor: pointer;
}

.s_edict {
  position: fixed;
  z-index: 1000;
  top: 100px;
  right: 0px;
  width: 250px;
  background-color: #f36666;
  box-shadow: 4px -1px 6px 4px #716b6b;
  border-radius: 0px 20px 20px 20px;
}

.s_edictList {
  padding: 5px;
  border-top: solid 0.5px #716b6b;
  margin: 0px;
}

.s_edictList:hover {
  padding: 5px;
  border-top: solid 0.5px #716b6b;
  margin: 0px;
  background-color: red;
  cursor: pointer;
}

</style>

