<template>
  <div>
    <b-row>
      <b-col sm="12" lg="11">
        <template v-if="p_multi_wdmr_data.wdmr_settings.multi_wdmr_schema.schema_view_mode === 'basic'">
          <b-row style="margin: 0px;" key="d_selectedSchemaType">
            <b-col sm="12" md="2" style="padding: 3px;">
              <b-dropdown variant="secondary" size="md" title="Wisdom: sistem şablonlarını, Hastane: hastane şablonlarını, Benim: size ait şablonları ifade eder">
                <template v-slot:button-content>
                  {{ d_schemaOptions[d_selectedSchemaType].translation.tr }}
                </template>
                <b-dropdown-item v-for="(schema_option, schema_option_ind) in d_schemaOptions" :key="'schema_type_option_2_'+ schema_option_ind" @click="f_selectSchemaOption(schema_option_ind)">{{ schema_option.translation.tr }}</b-dropdown-item>
              </b-dropdown>
            </b-col>
            <template v-if="d_selectedSchemaType !== 'favorite'">
              <b-col sm="12" md="2" style="padding: 3px;">
                <b-dropdown variant="secondary" size="md" title="Seçilen Grup Tipine Göre Filtreleme Yapmanızı Sağlar">
                  <template v-slot:button-content>
                    {{ d_schemaGroupTypeOptions[d_selectedSchemaGroupType].translation.tr }}
                  </template>
                  <b-dropdown-item v-for="(schema_group_type_option, schema_group_type_option_ind) in d_schemaGroupTypeOptions" :key="'schema_group_type_option_2_'+ schema_group_type_option_ind" @click="f_selectSchemaGroupTypeOption(schema_group_type_option_ind)">{{ schema_group_type_option.translation.tr }}</b-dropdown-item>
                </b-dropdown>
              </b-col>
              <b-col sm="12" md="8" v-if="d_selectedSchemaGroupType === 'selected'" style="padding-left: 3px;">
                <v-select :placeholder="d_schemaGroupsComing ? 'Gruplar Getiriliyor...' : ''" v-model="d_selectedSchemaGroup" name="selected_schema" :options="d_schemaGroupList">
                </v-select>
              </b-col>
            </template>
            <template v-else>
              <b-col sm="12" md="10" style="padding: 3px;">
                <v-select v-model="d_selectedSchema" name="selected_schema" :placeholder="d_schemaComing ? 'Şablonlar Getiriliyor...' : 'Şablon seçimi yapınız......'" :options="d_schemaList" label="label">
                  <template slot="option" slot-scope="option">
                    <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;">
                      <span> {{ option.label }} </span>
                    </div>
                  </template>
                </v-select>
              </b-col>
            </template>
          </b-row>
          <b-row v-if="d_selectedSchemaType !== 'favorite'" style="margin: 0px;">
            <b-col sm="12" md="12">
              <v-select v-model="d_selectedSchema" name="selected_schema" :options="d_schemaList">
                <template slot="option" slot-scope="option">
                  <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;">
                    <span> {{ option.label }} </span>
                  </div>
                </template>
              </v-select>
            </b-col>
          </b-row>
        </template>
        <template v-if="p_multi_wdmr_data.wdmr_settings.multi_wdmr_schema.schema_view_mode === 'up_down'">
          <b-card no-body header-tag="header" footer-tag="footer" class="mb-1">
            <b-card-header header-bg-variant="secondary" class="p-1" style="cursor: pointer;">
              <b-row>
                <b-col cols="6" v-b-toggle="'wdm_schema_multi'">
                  <img src="@/icon/2958560.png" style="width: 2em;" />
                  <strong>Şablon Kullanımı</strong>
                </b-col>
                <b-col cols="6">
                  <b-dropdown class="pull-right" variant="secondary" size="md" right style="margin-right: 5px;">
                    <template v-slot:button-content>
                      <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 2.2em;" />
                    </template>
                    <b-dropdown-item v-if="p_multi_wdmr_data && p_multi_wdmr_data.multi_wdmr_list.length > 0 && d_selectedSchemaGroup && d_selectedSchemaGroup.value" @click="f_addNewSchema()">
                      <img src="@/icon/2766751.png" style="width: 2.5em;" /> Yeni şablon
                    </b-dropdown-item>
                    <b-dropdown-item v-if="d_selectedSchema && d_selectedSchema.value" @click="f_editSchema(d_selectedSchema.id)">
                      <img src="@/icon/2087726.png" style="width: 2.5em;" /> Şablonu güncelle
                    </b-dropdown-item>
                    <b-dropdown-item v-if="d_selectedSchemaGroupType === 'selected'" @click="f_addNewSchemaGroup()">
                      <img src="@/icon/2448909.png" style="width: 2.5em;" /> Yeni grup ekle
                    </b-dropdown-item>
                    <b-dropdown-item v-if="d_selectedSchema" @click="f_updateMultiWdmrFavoriteSchema('add')">
                      <img src="@/icon/1869589.png" style="width: 2.5em;" /> Favorilerime Ekle
                    </b-dropdown-item>
                    <b-dropdown-item v-if="d_selectedSchema" @click="f_updateMultiWdmrFavoriteSchema('delete')">
                      <img src="@/icon/2960590.png" style="width: 2.5em;" /> Favorilerimden sil
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </b-card-header>
            <b-collapse :visible="true" id="wdm_schema_multi">
              <b-row>
                <b-col md="12" lg="6">
                  <b-row style="margin: 2px;">
                    <b-col sm="12">
                      <small><strong>Şablon Tipi</strong></small>
                    </b-col>
                  </b-row>
                  <b-row style="margin: 2px;">
                    <b-col sm="12" md="12" lg="12">
                      <b-form-group label="">
                        <b-form-radio-group key="d_selectedSchemaType" v-model="d_selectedSchemaType">
                          <b-form-radio v-for="(op, op_ind) in d_schemaOptions" :key="'schema_type_option_'+ op_ind" :value="op_ind"> {{ op.translation.tr }} </b-form-radio>
                        </b-form-radio-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="12" lg="6">
                  <b-row style="margin: 2px;">
                    <b-col sm="12">
                      <small><strong>Şablon Grubu</strong></small>
                    </b-col>
                  </b-row>
                  <b-row style="margin: 2px;">
                    <b-col sm="12" md="12" lg="12">
                      <b-form-group label="">
                        <b-form-radio-group key="d_selectedSchemaGroupType" v-model="d_selectedSchemaGroupType">
                          <b-form-radio v-for="(op, op_ind) in d_schemaGroupTypeOptions" :key="'schema_group_type_option_'+ op_ind" :value="op_ind"> {{ op.translation.tr }} </b-form-radio>
                        </b-form-radio-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row v-if="d_selectedSchemaGroupType === 'selected'">
                <b-col md="12" lg="12">
                  <b-row style="margin: 2px;">
                    <b-col sm="12">
                      <small><strong>Şablon Grubu</strong></small>
                    </b-col>
                  </b-row>
                  <b-row style="margin: 2px;">
                    <b-col sm="12" md="12" lg="12">
                      <v-select :placeholder="d_schemaGroupsComing ? 'Gruplar Getiriliyor...' : ''" :disabled="d_selectedSchemaGroupType === 'all' ? true : false" v-model="d_selectedSchemaGroup" name="selected_schema" :options="d_schemaGroupList" style="width:100%;">
                      </v-select>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" lg="12">
                  <b-row style="margin: 2px;">
                    <b-col sm="12">
                      <small><strong>Şablon Seçimi</strong></small>
                    </b-col>
                  </b-row>
                  <b-row style="margin: 2px;">
                    <b-col sm="12" md="12" lg="12">
                      <v-select v-model="d_selectedSchema" name="selected_schema" :placeholder="d_schemaComing ? 'Şablonlar Getiriliyor...' : 'Şablon seçimi yapınız......'" :options="d_schemaList" style="width: 100%;">
                        <template slot="option" slot-scope="option">
                          <div :key="option.value" style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;">
                            <span> {{ option.label }} </span>
                          </div>
                        </template>
                      </v-select>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-collapse>
          </b-card>
        </template>
      </b-col>
      <b-col sm="12" lg="1">
        <b-button class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" variant="secondary" @click="f_changeMultiSchemaMode()" title="Çoklu wdmr şablon modu değiştir">
          <img src="@/icon/2958560.png" style="width: 2.5em;" />
        </b-button>
      </b-col>
    </b-row>
    :<b-modal title="$t('wdm16.13229')" centered class="modal-success" v-model="d_showNewSchemaGroup" @ok="d_showNewSchemaGroup = false" ok-variant="success" hide-footer>
      <b-row v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 || user.permissions_result.indexOf('administrator') !== -1)">
        <b-col cols="3">
          <strong>{{ $t('wdm16.13230') }}</strong>
        </b-col>
        <b-col cols="9">
          <select v-model="d_newSchemaGroupOwnerType" class="form-control">
            <option value="user">{{ $t('wdm16.13231') }}</option>
            <option value="hospital">{{ $t('wdm16.13232') }}</option>
            <option value="wisdom">{{ $t('wdm16.13233') }}</option>
          </select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <strong>{{ $t('wdm16.7119') }}</strong>
        </b-col>
        <b-col cols="9">
          <b-form-input type="text" class="form-control-warning" id="d_newSchemaGroupTitle" v-model="d_newSchemaGroupTitle"></b-form-input>
        </b-col>
      </b-row>
      <br>
      <b-row class="pull-right">
        <b-col cols="12">
          <b-button v-if="d_newSchemaGroupTitle !== ''" variant="success" @click="f_saveMultiWdrmSchemaGroup()">{{ $t('wdm16.4061') }}</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal title="Şablon kaydetme ekranı" centered class="modal-success" v-model="d_showNewSchema" @ok="d_showNewSchema = false" ok-variant="success" hide-footer>
      <b-row>
        <b-col cols="3">
          <strong>{{ $t('wdm16.6772') }}</strong>
        </b-col>
        <b-col cols="9">
          <b-form-input type="text" class="form-control-warning" id="d_newSchemaTitle" v-model="d_newSchemaTitle"></b-form-input>
        </b-col>
      </b-row>
      <br>
      <b-row class="pull-right">
        <b-col cols="12">
          <b-button v-if="d_newSchemaTitle !== ''" variant="success" @click="f_saveMultiWdmrSchema()">{{ $t('wdm16.4061') }}</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import UserService from "@/services/users";
import { ClsUserSettings } from '@/services/public/user';
import { mapGetters } from 'vuex';
import vSelect from "vue-select";
import SchemaService from "@/services/schema";
import GlobalService from '@/services/global';
import { ClsWdmrForever } from "@/services/public/wdm";

export default {
  name: 'WdmrSchemaMulti',
  computed: {
    ...mapGetters({
      lang: 'lang',
      help: 'help'
    })
  },
  props: {
    p_patientData: {
      type: Object,
      required: true
    },
    p_multi_wdmr_data: {
      type: Object,
      required: true
    },
    p_viewMode: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      d_schemaComing: false,
      d_schemaOptions: {
        'favorite': { 'translation': { 'tr': 'Favori', 'en': 'Favorite' } },
        'user': { 'translation': { 'tr': 'Benim', 'en': 'My' } },
        'hospital': { 'translation': { 'tr': 'Hastane', 'en': '' } },
        'wisdom': { 'translation': { 'tr': 'Wisdom', 'en': 'Wisdom' } }
      },
      d_schemaGroupTypeOptions: {
        'all': { 'translation': { 'tr': 'Tümü', 'en': 'All' } },
        'selected': { 'translation': { 'tr': 'Seçilen', 'en': 'Selected' } }
      },
      d_schemaGroupsComing: false,
      user: {},
      d_selectedSchemaType: 'wisdom',
      d_selectedSchemaGroup: '',
      d_selectedSchemaGroupType: 'all',
      d_newSchemaTitle: '',
      d_showNewSchema: false,
      d_showNewSchemaGroup: false,
      d_schemaGroupList: [],
      d_wisdomSchemaGroupList: [],
      d_userSchemaGroupList: [],
      d_hospitalSchemaGroupList: [],
      d_schemaList: [],
      d_newSchemaGroupTitle: '',
      d_newSchemaGroupOwnerType: 'user',
      d_selectedSchema: '',
      d_schemaSaveType: 'new',
      d_mountingPage: false,
    };
  },
  created () {
    this.user = JSON.parse(localStorage.getItem('user'));
    // console.log(JSON.stringify(this.p_multi_wdmr_data.wdmr_settings.multi_wdmr_schema.d_selectedSchema));
    if (this.p_multi_wdmr_data.wdmr_settings.multi_wdmr_schema.d_selectedSchemaType) {
      this.d_selectedSchemaType = this.p_multi_wdmr_data.wdmr_settings.multi_wdmr_schema.d_selectedSchemaType;
    }
    if (this.p_multi_wdmr_data.wdmr_settings.multi_wdmr_schema.d_selectedSchemaGroupType) {
      this.d_selectedSchemaGroupType = this.p_multi_wdmr_data.wdmr_settings.multi_wdmr_schema.d_selectedSchemaGroupType;
    }
    if (this.p_multi_wdmr_data.wdmr_settings.multi_wdmr_schema.d_selectedSchemaGroup) {
      this.d_selectedSchemaGroup = this.p_multi_wdmr_data.wdmr_settings.multi_wdmr_schema.d_selectedSchemaGroup;
    }
    if (this.p_multi_wdmr_data.wdmr_settings.multi_wdmr_schema.d_selectedSchema) {
      this.d_selectedSchema = this.p_multi_wdmr_data.wdmr_settings.multi_wdmr_schema.d_selectedSchema;
      this.d_mountingPage = true;
      this.f_useSchema(true);
    }
    this.f_getMultiWdmrSchemaGroups();
    this.f_getMultiWdmrSchemaList();
  },
  mounted () {
  },
  methods: {
    f_updateMultiWdmrFavoriteSchema: function (type) {
      let user_data = JSON.parse(localStorage.getItem('user'));
      let schema_data = JSON.parse(JSON.stringify(this.p_multi_wdmr_data.wdmr_settings.multi_wdmr_schema.d_selectedSchema));
      schema_data.d_selectedSchemaType = this.d_selectedSchemaType;
      if (type === 'delete') {
        try {
          let det_ind = '';
          for (let i in user_data.settings.wdmr_record.settings.multi_wdmr_favorite_schema_list.settings.list) {
            if (user_data.settings.wdmr_record.settings.multi_wdmr_favorite_schema_list.settings.list[i].value === schema_data.value) {
              det_ind = i;
              break;
            }
          }
          if (det_ind !== '') {
            user_data.settings.wdmr_record.settings.multi_wdmr_favorite_schema_list.settings.list.splice(det_ind, 1);
          }
        } catch (err) {}
        this.d_schemaList = [];
      } else if (type === 'add') {
        ClsUserSettings.free_structure(user_data);
        let det = false;
        for (let i in user_data.settings.wdmr_record.settings.multi_wdmr_favorite_schema_list.settings.list) {
          if (user_data.settings.wdmr_record.settings.multi_wdmr_favorite_schema_list.settings.list[i] === schema_data.value) {
            det = true;
            break;
          }
        }
        if (!det) {
          user_data.settings.wdmr_record.settings.multi_wdmr_favorite_schema_list.settings.list.push(schema_data);
        }
      }
      let data = {
        'schema': schema_data,
        'type': type
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Favori şablon listeniz güncelleniyor. Lütfen bekleyiniz.' } });
      UserService.update_multi_wdmr_favorite_schema(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            alert(this.$t('wdm16.12713'));
            localStorage.setItem('user', JSON.stringify(user_data));
            this.user = user_data;
            this.$forceUpdate();
          } else {
            alert(this.$t('wdm16.12714'), resp.data.message);
          }
        });
      // localStorage.user = JSON.stringify(user_data);
    },
    f_changeMultiSchemaMode: function () {
      if (this.p_multi_wdmr_data.wdmr_settings.multi_wdmr_schema.schema_view_mode === 'basic') {
        this.p_multi_wdmr_data.wdmr_settings.multi_wdmr_schema.schema_view_mode = 'up_down';
      } else if (this.p_multi_wdmr_data.wdmr_settings.multi_wdmr_schema.schema_view_mode === 'up_down') {
        this.p_multi_wdmr_data.wdmr_settings.multi_wdmr_schema.schema_view_mode = 'basic';
      }
      this.$forceUpdate();
    },
    f_selectSchemaGroupTypeOption: function (schema_group_type_option_ind) {
      this.d_selectedSchemaGroupType = schema_group_type_option_ind;
      this.$forceUpdate();
    },
    f_selectSchemaOption: function (schema_option_ind) {
      this.d_selectedSchemaType = schema_option_ind;
      this.$forceUpdate();
    },
    f_editSchema: function (schema_id) {
      this.d_schemaSaveType = schema_id;
      this.d_newSchemaTitle = this.d_selectedSchema.label;
      this.d_showNewSchema = true;
    },
    f_addNewSchema: function () {
      this.d_schemaSaveType = 'new';
      this.d_newSchemaTitle = '';
      for (let i in this.p_multi_wdmr_data.multi_wdmr_list) {
        if (!this.p_multi_wdmr_data.multi_wdmr_list[i].schema_name) {
          alert('Lütfen grup adlarını tamamlayınız.');
          return;
        }
      }
      this.d_showNewSchema = true;
    },
    f_changeSelectedSchemaType: function () {
      this.f_getMultiWdmrSchemaGroups();
      this.$forceUpdate();
    },
    f_addNewSchemaGroup: function () {
      this.d_newSchemaGroupTitle = '';
      this.d_newSchemaGroupOwnerType = this.d_selectedSchemaType;
      this.d_showNewSchemaGroup = true;
    },
    f_useSchema: function (d_mountingPage = false) {
      this.p_multi_wdmr_data.multi_wdmr_list = [];
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Şablon yükleniyor.' } });
      let type = '';
      if (this.d_selectedSchemaType === 'favorite') {
        type = this.d_selectedSchema.d_selectedSchemaType;
      } else {
        type = this.d_selectedSchemaType;
      }
      SchemaService.get_multi_wdmr_schema_data({ 'schema_key': this.d_selectedSchema.value, 'owner_type': type, 'patient_id': this.p_patientData.id })
        .then(resp => {
          // console.log(resp.data.result);
          if (resp.data.status === 'success') {
            this.p_multi_wdmr_data.multi_wdmr_list = resp.data.result;
            try {
              this.p_multi_wdmr_data.wdmr_settings = this.user.settings.wdmr_record.settings.multi_wdmr_schema.settings[this.d_selectedSchema.value].wdmr_settings;
            } catch (err) {}
            if (this.p_multi_wdmr_data.selected_schema_change) {
              this.p_multi_wdmr_data.selected_schema_change = 0;
            } else {
              this.p_multi_wdmr_data.selected_schema_change = 1;
            }
            let will_load_any_schema = false;
            if (this.p_multi_wdmr_data.wdmr_settings.wdmr_schema.wdm.show || this.p_multi_wdmr_data.wdmr_settings.wdmr_schema.wdm_anatomy.show) {
              will_load_any_schema = true;
            }
            if (!will_load_any_schema) {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
            }
            this.d_mountingPage = false;
          }
        });
    },
    f_saveMultiWdmrSchema: function () {
      for (let i in this.p_multi_wdmr_data.multi_wdmr_list) {
        if (!this.p_multi_wdmr_data.multi_wdmr_list[i].schema_name) {
          alert('Lütfen veri modeli şablon adlarını tamamlayınız.');
          return;
        }
      }
      let request_data = {
        'username': this.user.username,
        'id': '',
        'schema_name': this.d_newSchemaTitle,
        'schema_group': '',
        'multi_wdmr_schema_list': [],
        'owner_type': this.d_selectedSchemaType
      };
      if (this.d_schemaSaveType === 'new') {
        request_data.id = 'new';
        request_data.schema_group = this.d_selectedSchemaGroup.value;
      } else {
        request_data.id = this.d_selectedSchema.id;
        request_data.schema_group = this.d_selectedSchema.group_value;
      }
      for (let i in this.p_multi_wdmr_data.multi_wdmr_list) {
        let x = this.p_multi_wdmr_data.multi_wdmr_list[i];
        let data = {
          'reference_wdm': {},
          'schema_data': {},
          'schema_name': x.schema_name
        };
        data.reference_wdm = {
          'data_type': x.data_type,
          'owner_type': x.option_data[x.data_type].owner_type,
          'version': x.option_data[x.data_type].version,
          'key': 'wdm_' + x.option_data[x.data_type].owner_type + '_' + x.data_type + '_v' + x.option_data[x.data_type].version
        };
        if (x.option_data[x.data_type].owner_type === 'hospital') {
          data.reference_wdm.key = 'wdm_' + x.option_data[x.data_type].owner_type + '_' + x.data_type + '_' + this.user.hospital_group + '_' + this.user.hospital_id + '_v' + x.option_data[x.data_type].version;
        }
        data.schema_data = ClsWdmrForever.turn(x.wisdom_data, x.option_data[x.data_type], ['schema']).schema;
        request_data.multi_wdmr_schema_list.push(data);
      }
      // console.log(JSON.stringify(request_data));
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Şablon kayıt işlemi yapılıyor. Lütfen bekleyiniz...' } });
      SchemaService.save_multi_wdmr_schema(request_data)
        .then(resp => {
          if (resp.data.status === 'success') {
            if (this.d_schemaSaveType === 'new') {
              this.d_schemaList.push(resp.data.result);
            } else {
              for (let i in this.d_schemaList) {
                if (this.d_schemaList[i].value === resp.data.result.value) {
                  this.d_schemaList[i] = resp.data.result;
                  this.d_selectedSchema = this.d_schemaList[i];
                  break;
                }
              }
            }
            this.$forceUpdate();
          } else {
            alert('Error: ', resp.data.message);
          }
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          this.d_showNewSchema = false;
        });
    },
    f_saveMultiWdrmSchemaGroup: function () {
      let data = {
        'owner_type': this.d_newSchemaGroupOwnerType,
        'username': this.user.username,
        'group_name': this.d_newSchemaGroupTitle
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Grup kayıt işlemi yapılıyor. Lütfen bekleyiniz...' } });
      SchemaService.save_multi_wdmr_schema_group(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          if (resp.data.status === 'success') {
            this.d_schemaGroupList = resp.data.result.list;
          } else {
            alert('error: ', resp.data.message);
          }
          this.d_showNewSchemaGroup = false;
        });
    },
    f_getMultiWdmrSchemaList: function () {
      this.d_schemaComing = true;
      this.d_schemaList = [];
      this.$forceUpdate();
      if (this.d_selectedSchemaType === 'favorite') {
        let user_data = JSON.parse(localStorage.getItem('user'));
        try {
          this.d_schemaList = user_data.settings.wdmr_record.settings.multi_wdmr_favorite_schema_list.settings.list;
        } catch (err) {}
        this.d_schemaComing = false;
      } else {
        let data = {
          'username': this.user.username,
          'owner_type': this.d_selectedSchemaType, // wisdom, user, hospital
          'group_type': this.d_selectedSchemaGroupType, // all, selected
          'schema_group': this.d_selectedSchemaGroup && this.d_selectedSchemaGroup.value ? this.d_selectedSchemaGroup.value : ''
        };
        // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Yükleniyor...' } });
        SchemaService.get_multi_wdmr_schema_list(data)
          .then(resp => {
            // this.$store.commit('Loading', { 'status': false, 'data': {} });
            this.d_schemaComing = false;
            if (resp.data.status === 'success') {
              this.d_schemaList = resp.data.result;
              /*
                if (this.d_schemaList.length > 0) {
                  this.d_selectedSchema = this.d_schemaList[this.d_schemaList.length - 1];
                } else {
                  this.d_selectedSchema = '';
                }
              */
            } else {
              alert('error: ', resp.data.message);
            }
          });
      }
    },
    f_getMultiWdmrSchemaGroups: function () {
      if (this.d_selectedSchemaGroupType === 'selected') {
        let buck = 'hospital';
        let group_key = 'schema_multi_group_';
        if (this.d_selectedSchemaType === 'wisdom') {
          buck = 'option';
          group_key += 'wisdom';
        } else if (this.d_selectedSchemaType === 'user') {
          group_key += 'user_' + this.user.username;
        } else if (this.d_selectedSchemaType === 'user') {
          group_key += 'hospital_' + this.user.hospital_group + '_' + this.user.hospital_id;
        }
        this.d_schemaGroupsComing = true;
        // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Yükleniyor...' } });
        GlobalService.get_one_cb_document({ 'key': group_key, 'bucket': buck })
          .then(resp => {
            // this.$store.commit('Loading', { 'status': false, 'data': {} });
            // console.log(resp.data.result);
            if (resp.data.status === 'success') {
              this.d_schemaGroupList = resp.data.result.list;
              if (this.d_schemaGroupList && this.d_schemaGroupList.length > 0) {
                this.d_selectedSchemaGroup = this.d_schemaGroupList[this.d_schemaGroupList.length - 1];
              }
            } else {
              //
            }
            this.d_schemaGroupsComing = false;
            this.$forceUpdate();
          });
      } else {
        this.d_schemaGroupList = [];
        this.d_selectedSchemaGroup = '';
      }
    },
    f_updateWdmrSchemaSettings: function () {
      this.p_multi_wdmr_data.wdmr_settings.multi_wdmr_schema.d_selectedSchema = this.d_selectedSchema;
      this.p_multi_wdmr_data.wdmr_settings.multi_wdmr_schema.d_selectedSchemaType = this.d_selectedSchemaType;
      this.p_multi_wdmr_data.wdmr_settings.multi_wdmr_schema.d_selectedSchemaGroupType = this.d_selectedSchemaGroupType;
      this.p_multi_wdmr_data.wdmr_settings.multi_wdmr_schema.d_selectedSchemaGroup = this.d_selectedSchemaGroup;
    }
  },
  watch: {
    'd_selectedSchema': function () {
      // console.log('this.d_selectedSchema', this.d_selectedSchema);
      if (!this.d_mountingPage) {
        this.f_updateWdmrSchemaSettings();
        this.p_multi_wdmr_data.multi_wdmr_list = [];
        if (this.d_selectedSchema) {
          this.f_useSchema();
        }
      }
    },
    'd_selectedSchemaType': function () {
      // console.log('d_selectedSchemaType', this.d_selectedSchemaType);
      // console.log('this.d_mountingPage ', this.d_mountingPage);
      if (!this.d_mountingPage) {
        this.f_updateWdmrSchemaSettings();
        this.p_multi_wdmr_data.multi_wdmr_list = [];
        this.d_selectedSchema = '';
        this.d_selectedSchemaGroupType = 'all';
        this.f_getMultiWdmrSchemaList();
      }
    },
    'd_selectedSchemaGroup': function () {
      // console.log('d_selectedSchemaGroup', this.d_selectedSchemaGroup);
      if (!this.d_mountingPage) {
        this.f_updateWdmrSchemaSettings();
        this.p_multi_wdmr_data.multi_wdmr_list = [];
        this.f_getMultiWdmrSchemaList();
      }
    },
    'd_selectedSchemaGroupType': function () {
      // console.log('d_selectedSchemaGroupType', this.d_selectedSchemaGroupType);
      if (!this.d_mountingPage) {
        this.f_updateWdmrSchemaSettings();
        this.p_multi_wdmr_data.multi_wdmr_list = [];
        this.d_selectedSchema = '';
        this.f_getMultiWdmrSchemaList();
        this.f_getMultiWdmrSchemaGroups();
      }
    }
  },
  components: {
    vSelect
  }
};

</script>

<style type="text/css">


</style>

